import './style.scss';

import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import React from 'react';
import Modal from 'react-modal';

import { TransferGoalData } from '../../common/types';
import { RootState } from '../../redux/reducers';
import { connect } from 'react-redux';

type PropsFromParent = {
  id: string;
};

type PropsFromState = {
  data?: TransferGoalData;
};

type AllProps = PropsFromParent & PropsFromState;

type State = {
  isModalOpen: boolean;
};

Modal.setAppElement('#root');

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: 0,
    padding: 0,
  },
  overlay: {
    zIndex: 10001, // Dexter's header has a z-index of 10000
  },
};

class TransferGoal extends React.PureComponent<AllProps, State> {
  _modalCloseIconSvg: any;

  state = {
    isModalOpen: false,
  };

  openModal = () => {
    this.setState({ isModalOpen: true });
  };

  closeModal = () => {
    this.setState({ isModalOpen: false });
  };

  createMarkup = (html: string) => {
    return { __html: html };
  };

  renderModal = () => {
    const { data } = this.props;

    if (!data) {
      return null;
    }

    return (
      <Modal
        isOpen={this.state.isModalOpen}
        onRequestClose={this.closeModal}
        style={modalStyles}
        contentLabel="Transfer Goal Modal"
      >
        <div className="transfer-goal__modal">
          <div className="transfer-goal__modal-header">
            <h1 className="transfer-goal__modal-header-text">{data.name}</h1>
            <FontAwesomeIcon
              className="transfer-goal__modal-close-icon"
              icon={faTimesCircle}
              size={window.innerWidth < 1920 ? '2x' : '4x'}
              onClick={this.closeModal}
              color="#FFFFFF"
            />
          </div>
          <div className="transfer-goal__modal-content-scroll-box">
            <div className="transfer-goal__modal-content" dangerouslySetInnerHTML={this.createMarkup(data.content)} />
          </div>
        </div>
      </Modal>
    );
  };

  render() {
    const { data } = this.props;

    if (!data) {
      return null;
    }

    return (
      <div className="transfer-goal">
        <div className="transfer-goal__button" onClick={this.openModal}>
          <div className="transfer-goal__img" style={{ backgroundImage: `url('${data.thumbnail_url}')` }} />
          <div className="transfer-goal__label">
            <h6 className="transfer-goal__label-text">{data.name}</h6>
          </div>
        </div>
        {this.renderModal()}
      </div>
    );
  }
}

const mapStateToProps = (state: RootState, ownProps: PropsFromParent): PropsFromState => {
  return {
    data: _.find(state.data.transferGoals, ['id', ownProps.id]),
  };
};

export default connect(mapStateToProps)(TransferGoal);
