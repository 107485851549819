import { AnyAction, combineReducers } from 'redux';
import { getType } from 'typesafe-actions';

import { TransferGoalData, VisionCategoryData } from '../../common/types';
import CommonActions from '../actions';
import data from './data';
import visionUi from './visionUi';

export interface VisionUiState {
  lastUpdatedInStore: Date;
  activeIndex: number;
  direction: 'forward' | 'backward';
  isAnimationActive: boolean;
}

export interface DataState {
  lastUpdatedInStore: Date;
  isDataFetchFinished: boolean;
  visionCategories: VisionCategoryData[];
  transferGoals: TransferGoalData[];
}

export interface RootState {
  visionUi: VisionUiState;
  data: DataState;
}

const appReducer = combineReducers<RootState>({
  visionUi,
  data,
});

export const rootReducer = (state: any, action: AnyAction): RootState => {
  if (action.type === getType(CommonActions.resetStore)) {
    // Triggers initializers for all nested state in the store
    // @ts-ignore
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};
