const COMMON = {
  RESET_STORE: 'common/RESET_STORE',
};

const VISION = {
  SET_ACTIVE_INDEX: 'vision/SET_ACTIVE_INDEX',
  DECREMENT_ACTIVE_INDEX: 'vision/DECREMENT_ACTIVE_INDEX',
  INCREMENT_ACTIVE_INDEX: 'vision/INCREMENT_ACTIVE_INDEX',
  SET_IS_ANIMATION_ACTIVE: 'vision/SET_IS_ANIMATION_ACTIVE',
};

const DATA = {
  SET_DATA: 'data/SET_DATA',
  SET_IS_DATA_FETCH_FINISHED: 'data/SET_IS_DATA_FETCH_FINISHED',
};

const ActionConstants = {
  COMMON,
  VISION,
  DATA,
};

export default ActionConstants;
