import './style.scss';

import _ from 'lodash';
import React, { ReactElement } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { connect } from 'react-redux';

import MemoizeUtils from '../../common/utils/memoize';
import { RootState } from '../../redux/reducers';
import VisionCard from './Card';

type PropsFromParent = {};

type PropsFromState = {
  activeIndex: number;
  categoryIds: string[];
};

type PropsFromDispatch = {};

type AllProps = PropsFromParent & PropsFromDispatch & PropsFromState;

class VisionCarousel extends React.Component<AllProps> {
  _mainContainer: any;

  constructor(props: AllProps) {
    super(props);
    this._mainContainer = React.createRef();
  }

  state = {
    carouselContentWidth: 0,
  };

  componentDidMount() {
    const ref = this._mainContainer.current;
    // Initialize cardWidth exactly once
    if (_.isNil(ref) || !_.isUndefined(this.state.carouselContentWidth)) {
      return;
    }

    this.setState({ carouselContentWidth: ref.clientWidth * 0.9 });
  }

  renderCards = () => {
    const { categoryIds } = this.props;
    const rows: ReactElement[] = [];

    categoryIds.forEach((id: string) => {
      rows.push(
        <Carousel.Item key={`vision-card-${id}`}>
          <VisionCard id={id} />
        </Carousel.Item>,
      );
    });

    return rows;
  };

  render() {
    const { activeIndex } = this.props;

    return (
      <div className="vision-carousel" ref={this._mainContainer}>
        <div className="vision-carousel__gradient" />
        <Carousel
          activeIndex={activeIndex}
          className="vision-carousel__content"
          onSelect={() => {
            // Do nothing
          }}
          indicators={false}
          controls={false}
          interval={null}
          keyboard={false}
          touch={false}
        >
          {this.renderCards()}
        </Carousel>
      </div>
    );
  }
}

// Only cause render if the ids of the vision categories are updated
const getVisionCategoryIds = MemoizeUtils.deepEqualSelectorCreator(
  (state: RootState) => _.map(state.data.visionCategories, 'id'),
  (categoryIds: string[]) => categoryIds,
);

const mapStateToProps = (state: RootState): PropsFromState => {
  const { activeIndex } = state.visionUi;

  return {
    activeIndex,
    categoryIds: getVisionCategoryIds(state),
  };
};

export default connect(mapStateToProps)(VisionCarousel);
