import { AnyAction } from 'redux';
import { getType } from 'typesafe-actions';

import { VisionUiState } from '.';
import { _PureVisionActions } from '../actions/vision';

const initVisionUiState: VisionUiState = {
  lastUpdatedInStore: new Date(0),
  activeIndex: 0,
  direction: 'forward',
  isAnimationActive: false,
};

const visionUi = (state = initVisionUiState, action: AnyAction): VisionUiState => {
  switch (action.type) {
    case getType(_PureVisionActions.setActiveIndex):
      const { activeIndex } = state;
      const newIndex = action.payload;

      if (newIndex === activeIndex) {
        return state;
      }

      let direction: 'backward' | 'forward' = 'backward';
      if (newIndex > activeIndex) {
        direction = 'forward';
      }

      return {
        ...state,
        direction,
        lastUpdatedInStore: new Date(),
        activeIndex: newIndex,
      };
    case getType(_PureVisionActions.decrementActiveIndex): {
      const { activeIndex } = state;

      if (activeIndex === 0) {
        return state;
      }

      return {
        ...state,
        lastUpdatedInStore: new Date(),
        activeIndex: state.activeIndex - 1,
        direction: 'backward',
      };
    }
    case getType(_PureVisionActions.incrementActiveIndex): {
      // No check for > last index since content is dynamic, and the UI should not allow it when at the end

      return {
        ...state,
        lastUpdatedInStore: new Date(),
        activeIndex: state.activeIndex + 1,
        direction: 'forward',
      };
    }
    case getType(_PureVisionActions.setIsAnimationActive):
      const isActive = action.payload;

      return {
        ...state,
        isAnimationActive: isActive,
      };
    default:
      return state;
  }
};

export default visionUi;
