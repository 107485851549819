import './style.scss';

import React, { ReactElement } from 'react';
import { connect } from 'react-redux';

import { VisionCategoryData } from '../../common/types';
import { RootState } from '../../redux/reducers';

type PropsFromState = {
  data: VisionCategoryData[];
};

type Props = PropsFromState;

class MobileVisionContent extends React.PureComponent<Props> {
  createMarkup = (html: string) => {
    return { __html: html };
  };

  renderCorePrincipleHeader = (text: string, color: string) => {
    return (
      <div className="vision-category-content__header" style={{ backgroundColor: color }}>
        <h2 className="vision-category-content__header-text">{text}</h2>
      </div>
    );
  };

  renderBody = () => {
    const { data } = this.props;
    const rows: ReactElement[] = [];

    data.forEach((category: VisionCategoryData, index: number) => {
      rows.push(
        <div className="vision-category-content" key={category.id}>
          {/* Only render headers once per core principle, which occurs every 3 categories */}
          {(index - 1) % 3 === 0
            ? this.renderCorePrincipleHeader(category.core_principle!, category.color_header!)
            : null}
          {/* Ignore the Intro header */}
          {index > 0 ? <h3 className="vision-category-content__subheader-text">{category.name}</h3> : null}
          <div
            className="vision-category-content__body"
            dangerouslySetInnerHTML={this.createMarkup(category.content)}
          />
        </div>,
      );
    });

    return rows;
  };

  render() {
    return <div className="vision__content--mobile">{this.renderBody()}</div>;
  }
}

const mapStateToProps = (state: RootState): PropsFromState => {
  return {
    data: state.data.visionCategories,
  };
};

export default connect(mapStateToProps)(MobileVisionContent);
