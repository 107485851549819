import isDeepEqual from 'fast-deep-equal';
import { createSelectorCreator, defaultMemoize } from 'reselect';

/**
 * A custom selector (to be used for the `reselect` library) that overrides
 * the default equal comparitor with `fast-deep-equal`'s, allowing for input selectors
 * beyond just string values (objects, booleans, etc).
 */
const deepEqualSelectorCreator = createSelectorCreator(defaultMemoize, isDeepEqual);

const MemoizeUtils = {
  deepEqualSelectorCreator,
};

export default MemoizeUtils;
