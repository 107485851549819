import { AnyAction } from 'redux';

import * as backupData from '../../data/index.json';
import { DataState } from '.';
import { getType } from 'typesafe-actions';
import { DataActions } from '../actions/data';

// Initialize state with backup data in case fetching the updated version has issues
const initDataState: DataState = {
  lastUpdatedInStore: new Date(0),
  visionCategories: backupData.vision_categories,
  transferGoals: backupData.transfer_goals,
  isDataFetchFinished: false,
};

const data = (state = initDataState, action: AnyAction): DataState => {
  switch (action.type) {
    case getType(DataActions.setData):
      const { visionCategories, transferGoals } = action.payload;

      return {
        ...state,
        visionCategories,
        transferGoals,
        lastUpdatedInStore: new Date(),
      };
    case getType(DataActions.setIsDataFetchFinished):
      return {
        ...state,
        isDataFetchFinished: action.payload,
      };
    default:
      return state;
  }
};

export default data;
