import './style.scss';

import React from 'react';
import { connect } from 'react-redux';

import { RootState } from '../../redux/reducers';

type PropsFromParent = {
  imageFileName: string;
  pxInterval: number;
};

type PropsFromState = {
  lastUpdatedInStore: Date;
  direction: 'forward' | 'backward';
};

type PropsFromDispatch = {};

type AllProps = PropsFromParent & PropsFromDispatch & PropsFromState;

type State = {
  currentOffset: number;
  isInitialLoad: boolean;
};

/**
 * @param pxInterval The number of pixels to slide this component per animation
 */
class Wave extends React.Component<AllProps, State> {
  _wave: React.RefObject<HTMLDivElement> = React.createRef<HTMLDivElement>();
  _WIDTH_OF_IMAGE = 2400;

  state = {
    currentOffset: 0,
    isInitialLoad: true,
  };

  static getDerivedStateFromProps(props: AllProps, state: State) {
    if (state.isInitialLoad) {
      return { currentOffset: 0, isInitialLoad: false };
    }

    const { pxInterval } = props;

    const incrementOffset = props.direction === 'backward' ? pxInterval : -1 * pxInterval;
    return { currentOffset: state.currentOffset + incrementOffset };
  }

  render() {
    const { imageFileName } = this.props;
    const { currentOffset } = this.state;

    return (
      <div
        className="wave"
        style={{
          backgroundImage: `url('${require(`../../assets/${imageFileName}`)}')`,
          backgroundRepeat: 'repeat-x',
          transform: `translateX(${currentOffset}px)`,
        }}
        ref={this._wave}
      />
    );
  }
}

const mapStateToProps = (state: RootState): PropsFromState => {
  const { lastUpdatedInStore, direction } = state.visionUi;

  return {
    lastUpdatedInStore,
    direction,
  };
};

export default connect(mapStateToProps)(Wave);
