import './style.scss';

import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { RootState } from '../../../redux/reducers';
import { VisionActions } from '../../../redux/actions/vision';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';

type PropsFromParent = {
  navDirection: 'next' | 'back';
  label?: string;
};

type PropsFromState = {
  activeIndex: number;
  isAnimationActive: boolean;
};

type PropsFromDispatch = {
  handleClick: typeof VisionActions.incrementVisionIndex | typeof VisionActions.decrementVisionIndex;
};

type AllProps = PropsFromParent & PropsFromDispatch & PropsFromState;

class VisionNavButton extends React.PureComponent<AllProps> {
  renderArrowIcon = (arrowDirection: 'back' | 'next') => {
    const { navDirection } = this.props;
    if (navDirection !== arrowDirection) {
      return null;
    }

    let directionClassName = 'vision-carousel__nav-arrow--left';
    let icon = faArrowLeft;

    if (navDirection === 'next') {
      directionClassName = `vision-carousel__nav-arrow--right`;
      icon = faArrowRight;
    }

    return <FontAwesomeIcon icon={icon} className={`vision-carousel__nav-arrow ${directionClassName}`} />;
  };

  handleClick = () => {
    const { isAnimationActive } = this.props;

    // Wait for the animation to finish
    if (isAnimationActive) {
      return;
    }

    this.props.handleClick();
  };

  render() {
    const { navDirection, label, isAnimationActive } = this.props;

    // Continue intro button is standalone and needs no additional styling
    let addlClassNames = label !== 'Continue' ? `vision-carousel__nav-button--${navDirection}` : '';
    addlClassNames += isAnimationActive ? ' vision-carousel__nav-button--disabled' : '';

    return (
      <div className={`vision-carousel__nav-button ${addlClassNames}`} onClick={this.handleClick}>
        {this.renderArrowIcon('back')}
        <div>
          <h3>{label || (navDirection === 'next' ? 'Next' : 'Back')}</h3>
        </div>
        {this.renderArrowIcon('next')}
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): PropsFromState => {
  const { activeIndex, isAnimationActive } = state.visionUi;

  return {
    activeIndex,
    isAnimationActive,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>, ownProps: PropsFromParent): PropsFromDispatch => {
  return bindActionCreators(
    {
      handleClick:
        ownProps.navDirection === 'next' ? VisionActions.incrementVisionIndex : VisionActions.decrementVisionIndex,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(VisionNavButton);
