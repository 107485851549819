import { createCustomAction, createAction } from 'typesafe-actions';

import { TransferGoalData, VisionCategoryData } from '../../common/types';
import ActionConstants from './constants';

// Pure actions for use by thunks only
const setData = createCustomAction(
  ActionConstants.DATA.SET_DATA,
  (visionCategories: VisionCategoryData[], transferGoals: TransferGoalData[]) => ({
    payload: { visionCategories, transferGoals },
  }),
);
// Indicates if the GET request for data from s3 is finished, signaling all other dependent
// components to begin their initialization processes (in particular, Helm)
const setIsDataFetchFinished = createAction(
  ActionConstants.DATA.SET_IS_DATA_FETCH_FINISHED,
  (isFinished: boolean) => isFinished,
)();

export const DataActions = {
  setData,
  setIsDataFetchFinished,
};
