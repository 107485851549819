import ActionConstants from './constants';
import { createAction } from 'typesafe-actions';

const resetStore = createAction(ActionConstants.COMMON.RESET_STORE)<void>();

const CommonActions = {
  resetStore,
};

export default CommonActions;
