import './style.scss';

import React, { ReactElement } from 'react';
import { connect } from 'react-redux';

import { TransferGoalData } from '../../common/types';
import { RootState } from '../../redux/reducers';
import TransferGoal from './TransferGoal';

type PropsFromState = {
  data: TransferGoalData[];
};

type AllProps = PropsFromState;

class TransferGoals extends React.PureComponent<AllProps> {
  renderTransferGoals = () => {
    const { data } = this.props;
    const rows: ReactElement[] = [];

    data.forEach(tf => {
      rows.push(<TransferGoal key={tf.id} id={tf.id} />);
    });

    return rows;
  };

  render() {
    return (
      <div className="transfer-goals">
        <div className="transfer-goals__wrapper">{this.renderTransferGoals()}</div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): PropsFromState => {
  return {
    data: state.data.transferGoals,
  };
};

export default connect(mapStateToProps)(TransferGoals);
