import { Dispatch } from 'react';
import { createAction } from 'typesafe-actions';

import CommonConstants from '../../common/constants';
import { delay } from '../../common/utils';
import ActionConstants from './constants';

// Pure actions for use by thunks only
const setActiveIndex = createAction(ActionConstants.VISION.SET_ACTIVE_INDEX, (index: number) => index)();
const decrementActiveIndex = createAction(ActionConstants.VISION.DECREMENT_ACTIVE_INDEX)<void>();
const incrementActiveIndex = createAction(ActionConstants.VISION.INCREMENT_ACTIVE_INDEX)<void>();
const setIsAnimationActive = createAction(
  ActionConstants.VISION.SET_IS_ANIMATION_ACTIVE,
  (isActive: boolean) => isActive,
)();

/**
 * A thunk that sets the active Vision index, along with all relevant state in the store.
 * @param index The active Vision index.
 */
const setVisionIndex = (index: number) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(setIsAnimationActive(true));

    dispatch(setActiveIndex(index));

    await delay(CommonConstants.ANIMATION_DURATION);
    dispatch(setIsAnimationActive(false));
  };
};

/**
 * A thunk that increments the active Vision index, along with all relevant state in the store.
 */
const incrementVisionIndex = () => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(setIsAnimationActive(true));

    dispatch(incrementActiveIndex());

    await delay(CommonConstants.ANIMATION_DURATION);
    dispatch(setIsAnimationActive(false));
  };
};

/**
 * A thunk that decrements the active Vision index, along with all relevant state in the store.
 */
const decrementVisionIndex = () => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(setIsAnimationActive(true));

    dispatch(decrementActiveIndex());

    await delay(CommonConstants.ANIMATION_DURATION);
    dispatch(setIsAnimationActive(false));
  };
};

// Not to be dispatched outside of thunks
export const _PureVisionActions = {
  setActiveIndex,
  incrementActiveIndex,
  decrementActiveIndex,
  setIsAnimationActive,
};

// Public actions
export const VisionActions = {
  setVisionIndex,
  incrementVisionIndex,
  decrementVisionIndex,
};
