import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import './index.scss';
import 'bootstrap/dist/css/bootstrap.min.css';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import App from './App';
import store from './redux/store';
import * as serviceWorker from './serviceWorker';

// eslint-disable-next-line @typescript-eslint/no-var-requires
// const whyDidYouRender = require('@welldone-software/why-did-you-render');

// whyDidYouRender(React, {
//   collapseGroups: true,
//   include: [/.*/],
// });

const rootElement = document.getElementById('root');
ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  rootElement,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
