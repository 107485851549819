import './style.scss';

import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';

import { VisionCategoryData } from '../../../common/types';
import { RootState } from '../../../redux/reducers';
import NavButton from '../NavButton';

type PropsFromParent = {
  id: string;
  width?: string;
};

type PropsFromState = {
  data?: VisionCategoryData;
  isLast: boolean;
};

type PropsFromDispatch = {};

type AllProps = PropsFromParent & PropsFromDispatch & PropsFromState;

/**
 * @param id The id of the Vision Category this card renders content for
 */
class VisionCard extends React.Component<AllProps> {
  shouldComponentUpdate(nextProps: AllProps) {
    // The parent, Bootstrap Carousel, triggers new object references of props
    // to Carousel.Items often. Prevent all unnecessary descendant renders
    // from occurring
    if (nextProps.id === this.props.id) {
      return false;
    }

    return true;
  }

  isIntro = () => {
    const { data } = this.props;

    if (_.get(data, 'name', '') === 'Introduction') {
      return true;
    }

    return false;
  };

  createMarkup = (html: string) => {
    return { __html: html };
  };

  renderPlaceholderBody = () => {
    return (
      <div className="vision-card__content">
        <h4>Placeholder Content</h4>
        <p>
          The requisite knowledge and skills that students need to learn efficiently to succeed in college, careers, and
          civic life. It is a process that enables students to become more proficient at developing a deeper
          understanding of rigorous academic content, and the ability to transfer and apply that understanding to novel
          problems and situations. It is also an outcome that results from the self-directed transfer of knowledge and
          skills.
        </p>
      </div>
    );
  };

  renderBody = () => {
    const { data } = this.props;

    if (_.isUndefined(data) || _.isEmpty(data.content)) {
      return this.renderPlaceholderBody();
    }

    return (
      <div className="vision-card__content-scroll-box">
        <div className="vision-card__content" dangerouslySetInnerHTML={this.createMarkup(data.content)} />
      </div>
    );
  };

  renderIntroCard = () => {
    const { data } = this.props;

    if (_.isUndefined(data) || _.isEmpty(data.content)) {
      return this.renderPlaceholderBody();
    }

    return (
      <div className="vision-card vision-card--intro">
        <div
          className="vision-card__content vision-card__content--intro"
          dangerouslySetInnerHTML={this.createMarkup(data.content)}
        />
        <div className="vision-card__navigation vision-card__navigation--intro">
          <NavButton navDirection="next" label="Continue" />
        </div>
      </div>
    );
  };

  renderHeader = () => {
    // Intro card has no header
    if (this.isIntro()) {
      return null;
    }

    const { data } = this.props;

    const headerText = _.get(data, 'core_principle', 'Header');
    const subHeaderText = _.get(data, 'name', 'Subheader');

    return (
      <div className="vision-card__header" style={{ backgroundColor: _.get(data, 'color_header', undefined) }}>
        <h2 className="vision-card__header-text">{headerText}:</h2>
        <h3 className="vision-card__subheader-text">{subHeaderText}</h3>
      </div>
    );
  };

  renderNavigation = () => {
    const { isLast } = this.props;

    return (
      <div className="vision-card__navigation">
        <NavButton navDirection="back" />
        {isLast ? null : <NavButton navDirection="next" />}
      </div>
    );
  };

  render() {
    if (this.isIntro()) {
      return this.renderIntroCard();
    }

    return (
      <div className="vision-card">
        {this.renderHeader()}
        {this.renderBody()}
        {this.renderNavigation()}
      </div>
    );
  }
}

const mapStateToProps = (state: RootState, ownProps: PropsFromParent): PropsFromState => {
  const { visionCategories } = state.data;
  const { id } = ownProps;

  return {
    data: _.find(visionCategories, ['id', id]),
    isLast: _.findIndex(visionCategories, ['id', id]) === visionCategories.length - 1,
  };
};

export default connect(mapStateToProps)(VisionCard);
