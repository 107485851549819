import './style.scss';

import React from 'react';

export default class MobileHelm extends React.PureComponent {
  render() {
    return (
      <div className="helm">
        <img
          src="http://dcs-helm.forgedbynewfoundry.com/images/helm-mobile.png"
          alt="The DCS Learner Profile in the form of a helm"
        />
      </div>
    );
  }
}
