import { applyMiddleware, createStore } from 'redux';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';

import { rootReducer } from './reducers';

const logger = createLogger({
  // predicate: (getState, action) => Config.IS_DEBUG === 'true' && !_.includes(action.type, 'UPSERT_'),
  timestamp: true,
  duration: true,
});

const store = createStore(rootReducer, applyMiddleware(thunk, logger));

export default store;
