import './App.scss';

import axios from 'axios';
import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import Helm from './components/Helm';
import MobileHelm from './components/Helm/mobile';
import TransferGoals from './components/TransferGoals';
import VisionCarousel from './components/VisionCarousel';
import MobileVisionContent from './components/VisionCarousel/mobile';
import Wave from './components/Wave';
import { DataActions } from './redux/actions/data';

type PropsFromDispatch = {
  setData: typeof DataActions.setData;
  setIsDataFetchFinished: typeof DataActions.setIsDataFetchFinished;
};

type Props = PropsFromDispatch;

class App extends React.Component<Props> {
  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    const { setData, setIsDataFetchFinished } = this.props;
    axios.defaults.baseURL = 'https://s3.us-east-2.amazonaws.com/dcs-learner-profile.forgedbynewfoundry.com/';
    axios.defaults.headers = {
      common: {
        'Content-Type': 'application/json',
      },
    };

    const res = await axios.get('/data/learner-profile-data.json');

    const visionCategories = _.get(res, 'data.vision_categories', []);
    const transferGoals = _.get(res, 'data.transfer_goals', []);
    if (_.isEmpty(visionCategories) || _.isEmpty(transferGoals)) {
      console.warn('Fresh data from server cannot be fetched. Backup version must be used.');
      return;
    }

    setData(visionCategories, transferGoals);
    setIsDataFetchFinished(true);
  };

  renderFooterText = () => {
    return (
      <div className="vision-footer">
        <h1 className="vision-footer__text">See how our vision is applied to student learning:</h1>
        {this.renderDownArrow()}
      </div>
    );
  };

  renderDownArrow = () => {
    return (
      <svg className="vision-arrow" width={50} height={50}>
        <path d="M10.286 14.802l14.745 14.71 14.746-14.71 4.53 4.53-19.276 19.28-19.275-19.28z" fill="#FFFFFF" />
        <path d="M0 0h50v50H0z" fill="none" />
      </svg>
    );
  };

  renderVisionContent = () => {
    // Mobile and non-mobile versions are set to display: none for appropriate screen sizes in CSS
    return (
      <>
        <div className="vision--mobile">
          <MobileHelm />
          <MobileVisionContent />
        </div>
        <div className="vision__interactive">
          <Helm />
          <VisionCarousel />
        </div>
      </>
    );
  };

  renderWaves = () => {
    return (
      <div className="waves">
        <Wave imageFileName="waves-back.png" pxInterval={1036} />
        <Wave imageFileName="waves-mid.png" pxInterval={668} />
        <Wave imageFileName="waves-front.png" pxInterval={466} />
        <div
          className="wave--mobile"
          style={{ backgroundImage: "url('http://dcs-helm.forgedbynewfoundry.com/images/waves-mobile.png')" }}
        />
        {this.renderFooterText()}
        <div className="waves__bottom-fill" />
      </div>
    );
  };

  render() {
    return (
      <div className="app">
        <div className="vision">
          {this.renderVisionContent()}
          {this.renderWaves()}
        </div>
        <TransferGoals />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch<any>): PropsFromDispatch => {
  return bindActionCreators(
    {
      setData: DataActions.setData,
      setIsDataFetchFinished: DataActions.setIsDataFetchFinished,
    },
    dispatch,
  );
};

export default connect(null, mapDispatchToProps)(App);
